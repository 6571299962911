import React, { Component } from 'react';
import "./tweet.scss"
import comment from "../resources/icons/Map/comment.png"
import retweet from "../resources/icons/Map/retweet.png"
import like from "../resources/icons/Map/like.png"
import share from "../resources/icons/Map/share.png"
import verified from "../resources/icons/Map/verified.png"
import pp1 from "../resources/icons/Map/pp1.png"
import pp2 from "../resources/icons/Map/pp2.png"
import pp3 from "../resources/icons/Map/pp3.png"
import pp4 from "../resources/icons/Map/pp4.png"


class Tweet extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            pp: pp1
        }

        this.randPp = this.randPp.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
    } 

    randPp(){
        var choice = Math.floor(Math.random() * 4);
        var proPic
        switch(choice){
            case 0:
                proPic = pp1;
                this.setState({
                    pp: proPic
                })
                return;
            case 1:
                proPic = pp2;
                this.setState({
                    pp: proPic
                })
                return;
            case 2:
                proPic = pp3;
                this.setState({
                    pp: proPic
                })
                return;
            case 3:
                proPic = pp4
                this.setState({
                    pp: proPic
                })
                return;
        }

        // console.log("pro pic is:" +proPic)

        // this.setState({
        //     pp: proPic
        // })
    }

    handleLoad(){
        this.randPp()
    }

    componentDidMount(){
        window.addEventListener('load', this.handleLoad);
    }


    render() { 
        return (
        <div className="tweet-block">
            <div className="tweet-header">
                <div className="tweet-name">
                <img className="tweet-icon-img" srcSet={this.state.pp} alt=""/>
                <div className="tweet-name-inner">{this.props.tweetName}</div>
                <img className="vericon" srcSet={verified} alt="" />
                </div>
            </div>
            <div className="tweet-content">
                {this.props.tweetContent}
            </div>
            <div className="tweet-footer">
                <div className="tweet-icon tweet-comment">
                    <img className="icon-image" srcSet={comment} alt="" />
                </div>
                <div className="tweet-icon tweet-retweet">
                    <img className="icon-image" srcSet={retweet} alt="" />
                </div>
                <div className="tweet-icon tweet-like">
                    <img className="icon-image" srcSet={like} alt="" />
                </div>
                <div className="tweet-icon tweet-share">
                    <img className="icon-image" srcSet={share} alt="" />
                </div>
            </div>

        </div>
        );
    }
}
 
export default Tweet;