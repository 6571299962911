import React, { Component } from 'react';
import tagline from "../resources/home/tagline-popup.svg";
import close from "../resources/icons/close.png"

import "./throw.scss"

class Throw extends React.Component {
    constructor(props){
        super(props);
        // this.toggleClose = this.toggleClose.bind(this);
    }

    
    toggleClose = () =>{
        this.props.onPopupChange();
    }

    fixLength(){
        // setTimeout(function(){
        //     document.getElementById("cfv_shade76").maxLength = "240";
        // }, 10000);
    }



    render() { 
        return (
        <div className={'lightbox ' + this.props.popup}>
            <div className="form-container">
                {/* <div className="logo-2"><img className="throw-logo" srcSet={logo} alt="" /></div>
                 */}
                <div className="col2 cap-img-container">
                    {/* <img class="cap-img" srcSet={hat} alt="" /> */}
                </div>
                <div className="col2 throw-form">
                <div className="tagline"><img srcSet={tagline} alt="" /></div>
                    <iframe onLoad={this.fixLength} className="new-form" src="https://distell.everlytic.net/public/forms/get/SuYTuW3cJN9kWPRI/ODBmYjBjOGJlZjE2MDJjYTUyODI5YmMwOGM0ZjExNTBmNDBkMjFkMA==" allowTransparency="true"></iframe>
                </div>
                <img srcSet={close} alt="" className="close" onClick={this.toggleClose} />
            </div>
        </div>
        );
    }
}
 
export default Throw;