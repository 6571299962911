import React from "react";
import "./terms.scss";
import close from "../resources/icons/close.png"

function Terms(props) {
    return (
        <div className={'lightbox ' + props.popup}>
        <div className="terms-container">
             <img srcSet={close} alt="" className="close" onClick={() =>props.onPopupChange()} />
             <div className="content">
             <ol class="tc-ol">
 	<li><strong><u>SAVANNA TWIRRA SHADE PROJECT - COMPETITION RULES</u></strong><strong><u> </u></strong>

<strong><u> </u></strong>
<h3>1.     Introduction</h3>
<strong> </strong>
<ul>
 	<li>This promotional competition is organised by Distell (“the promoter”).</li>
</ul>
&nbsp;
<ul>
 	<li>The promotional competition is open to permanent residents of South Africa over the age of 18 years <u>except </u>any employee, director, member, partner, agent or consultant or any person directly or indirectly, who controls or is controlled by the Promoters, immediate family members, advertising agencies, advisers, dealers, suppliers and/or associated companies, and/or a participant who has won a prize through a promotional competition run by the Promoter at least three months prior to the announcement of the winner of the Prize (“Disqualified Persons”).</li>
</ul>
&nbsp;
<ul>
 	<li>By entering the promotional competition, all participants and winners agree to be bound by these rules which will be interpreted by the Promoters, whose decision regarding any dispute will be final and binding. The Promoters reserve the right to amend, modify, change, postpone, suspend or cancel this competition and any prizes (which have not yet been subject to a draw), or any aspect thereof, without notice at any time, for any reason which the Promoters reasonably deem necessary.</li>
</ul>
&nbsp;
<h3>2.     Duration and how to enter</h3>
&nbsp;
<ul>
 	<li>The promotional competition commences on 1 December 2021 and ends on 31 January 2022 both days inclusive. Entries received after the closing date will not be taken into consideration.</li>
</ul>
&nbsp;
<ul>
 	<li>Participants stand a chance to win merchandise – a custom made, Savanna branded 5-panel cap that features the shadiest tweets (“the Prize”)</li>
</ul>
&nbsp;
<ul>
 	<li>To be eligible for the Prize, participants must engage with Savanna Cider’s social posts on Twitter and follow the instructions, then they will be entered into a draw where the entries will be evaluated and selected by an independent party.</li>
</ul>
&nbsp;
<ul>
 	<li>Winners will be determined by random draw and notified by social media direct message oor telephone every two weeks, during the duration of the campaign. The Prize will be couriered to the winner/s within 30 days from providing their contact details and delivery address.</li>
</ul>
&nbsp;
<ul>
 	<li>Winners will be required to provide their proof of identity to verify that they are above the age of 18.</li>
</ul>
&nbsp;
<ul>
 	<li>The Prize is non-transferable and cannot be exchanged for cash.</li>
</ul>
&nbsp;
<ul>
 	<li>If the potential winners and/or winners do not claim their Prize on or before 90 days the potential winners and/or winners will be deemed to have rejected the Prize and it shall revert back to the Promoter.</li>
</ul>
&nbsp;
<ul>
 	<li>No applications from agents, third parties, organised groups or applications automatically generated by computer will be accepted. No incomplete or corrupted entries will be accepted. Entries will only be accepted if they comply with all entry instructions.</li>
</ul>
&nbsp;
<ul>
 	<li>Winners grant permission for the Promoters to use their names and photographs in any advertising and promotional material for this competition. Winners may ask that their names be removed or refuse to take part in any publicity. Participants will not receive any payment for taking part in the competition or taking part in any media format related to it.</li>
</ul>
&nbsp;
<ul>
 	<li>Responsibility is not accepted for entries lost, damaged or delayed as a result of any network, computer hardware or software failure of any kind. Proof of sending will not be accepted as proof of receipt.</li>
</ul>
&nbsp;
<ul>
 	<li>All participants and the winners, as the case may be, indemnify the Promoters, their advertising agencies, advisers, nominated agents, suppliers, its affiliates and/or associated companies against any and all claims of any nature whatsoever arising out of and/or from their participation in any way howsoever in this promotional competition (including, as a result of any act or omission, whether as a result of negligence, misrepresentation, misconduct or otherwise on the part of the Promoters) and/or use of the Prize.</li>
</ul>
&nbsp;
<ul>
 	<li>Under the Consumer Protection Act (CPA), we need to keep records of all the people used to promote the Promotional Competition. We (or one of our subcontractors or affiliates) will keep this agreement for three years to serve as the record as required by the CPA.</li>
</ul>
<h3>3.     Social media platforms</h3>
&nbsp;
<ul>
 	<li>This promotional competition is in no way sponsored, endorsed or administered by, or associated with WhatsApp,Facebook, Instagram or any other social media platform or any other social media platform that may be used to promote the competition. Participants acknowledge that they are providing information to the Promoter and its agents only and not to WhatsApp, Facebook, Instagram or any other social media platform.</li>
</ul>
&nbsp;
<ul>
 	<li>All entrants in this promotional competition release WhatsApp, Facebook, Instagram and all other social media platforms of all liabilities for and claims arising out of or in connection, including any damage you may suffer with this competition and these terms and conditions.</li>
</ul>
&nbsp;
<ul>
 	<li>A copy of the competition rules is available at <a href="http://www.savanna.co.za">savanna.co.za</a> and https://www.facebook.com/SavannaCider</li>
</ul>
&nbsp;
<h3>4.     Dispute resolution</h3>
&nbsp;
<ul>
 	<li>The Promoters’ decision is final and binding and no correspondence will be entered into if any dispute arises in relation to the interpretation of the competition rules, However, any party may use other dispute resolution channels provided for by the CPA or other law.</li>
</ul>
&nbsp;
<ul>
 	<li>This clause is separate and divisible from the rest of this agreement and stays effective even if this agreement ends or is invalid.</li>
</ul>
<h3>5.     Personal Information</h3>
&nbsp;
<ul>
 	<li>The participant understands and agrees that in order to conduct the competition, we must collect and process personal information about them. By entering this competition, the participant consents to the processing of his/her personal information. Read more about how we, Distell, process personal information in our <strong>privacy policy</strong> and how (insert name of other Promoter) processes personal information here.</li>
</ul>
<h3>6.     General Rules</h3>
&nbsp;
<ul>
 	<li>By taking part in this competition you agree that you will not hold us liable for any losses, harm, damages, injury, claims or actions related to this competition. We are not liable for any failure of any technical element relating to this promotional competition that may result in an entry not being submitted.</li>
</ul>
&nbsp;
<ul>
 	<li>Force majeure. No party will be responsible for any breach of this agreement caused by circumstances beyond its control.</li>
</ul>
&nbsp;
<ul>
 	<li>These rules, including the duration of the competition, can only be reasonably changed (or superseded) by us in a written revision to these rules posted on the competition website or any other potential official competition communication methods we use to reach a majority of potential participants.</li>
</ul>
&nbsp;
<ul>
 	<li>Income or other taxes relating to the prizes, if any, are the sole responsibility of the prize winner.</li>
</ul>
&nbsp;
<ul>
 	<li>South African laws govern this competition.</li>
</ul>
&nbsp;
<ul>
 	<li>If the Promoters need to, because of legislative or regulatory reasons, we may terminate the competition immediately and without notice. If this happens you will not have any claim against us.</li>
</ul>
</li>
</ol>
             </div>
    
        </div>
        </div>
    )
}

export default Terms;