import React, { Component } from "react";
import Map from "./map"
import tweets from "../resources/twirra.txt"
import TweetContainer from "./tweet-container"
import TweetCarousel from "./tweet-carousel"
import InfoTab from "./infoTab";
import InfoTabMobi from "./infoTabMobi";
import Footer from "./footer"
import Popup from "./popup"
import "./shade-container.scss"
import siya from "../resources/icons/siyavanna.svg";
import logo from "../resources/icons/logo.svg";
import shadow from "../resources/icons/shadow.png";

class ShadeContainer extends React.Component {
    state = {
        ec:0,
        fs:0,
        gauteng:0,
        kzn:0,
        limpopo:0,
        mpumalanga:0,
        nc:0,
        nw:0,
        wc:0,
        tweetArr: [],
        chosenProv: "Eastern Cape",
        masonOpen: "hidden",
        mapOpen: "visible",
        popup: "hidden",
        carOpen: "hidden",
        locLeft: "100px",
        locTop: "100px",
        currSlide: 0,
        tweetOrTree: "tweet",
        treeLoc:"Melrose"
    }
    constructor(props){
        super(props)

        // const reader = new FileReader();
        // console.log(tweets)
        // reader.onload = function(e){
        //     const text = e.target.result;
        //     console.log(text);
        // }
        // // this.updateData = this.updateData.bind(this);
        // reader.readAsText(tweets);

        this.toggleOpenMasonary = this.toggleOpenMasonary.bind(this);
        this.popUp = this.popUp.bind(this);
        this.togglePop = this.togglePop.bind(this);
        this.assignSlide = this.assignSlide.bind(this);
        this.closeCarousel = this.closeCarousel.bind(this);
    }

    componentDidMount(){
        fetch(tweets)
            .then(r => r.text())
            .then(text => {
                // console.log('text decoded:', text);
                this.textToArray(text)
            });
    }

    findAll = (arr, query) => {
        let count = 0;
        count = arr.filter(el => {
           return el.indexOf(query) != -1;
        }).length;
        return count;
     };

     setCounts(arr){
         this.setState({
            ec:this.findAll(arr, "Eastern Cape"),
            fs:this.findAll(arr, "Free State"),
            gauteng:this.findAll(arr, "Gauteng"),
            kzn:this.findAll(arr, "KwaZulu-Natal"),
            limpopo:this.findAll(arr, "Limpopo"),
            mpumalanga:this.findAll(arr, "Mpumalanga"),
            nc:this.findAll(arr, "Northern Cape"),
            nw:this.findAll(arr, "North West"),
            wc:this.findAll(arr, "Western Cape")
         })
     }

    textToArray(text){
        var store = text.split("\r\n");
        // console.log(store)
        var tweetArray = []
        for (var x in store){
            tweetArray.push(store[x].split("\t"))
        }
        // console.log(tweetArray)
        this.setCounts(tweetArray)
        tweetArray.shift()
        this.setState({
            tweetArr: tweetArray
        })
    }

    toggleMap(visible){
        this.setState({

        })
    }

    toggleOpenMasonary(visibility, prov, visPop, mapVisibility){
        this.setState({
            masonOpen: visibility,
            chosenProv:prov,
            popup: visPop,
            mapOpen:mapVisibility
        });
    }

    togglePop(visiblity){
        this.setState({
            popup: visiblity
        })

    }

    assignSlide(id){
        this.setState({
            currSlide: id,
            carOpen: "visible",
            masonOpen: "hidden"
        })
    }

    closeCarousel(){
        this.setState({
            carOpen: "hidden",
            masonOpen: "visible"
        })
    }

    popUp(locationLeft, locationTop, prov, whatShowing){
        // console.log(location)
        // console.log("Left:" + locationLeft + " Top:" + locationTop + " Province:" + prov)
        this.togglePop("visible");
        if(whatShowing == "tweet"){
            this.setState({
                locLeft: (locationLeft-150) + "px",
                locTop: (locationTop-220) +"px",
                chosenProv: prov,
                tweetOrTree: whatShowing
            })
        }
        else{
            this.setState({
                locLeft: (locationLeft-150) + "px",
                locTop: (locationTop-220) +"px",
                treeLoc: prov,
                tweetOrTree: whatShowing
            })
        }
    }




  render() {
    return (
      <div  className={"shade-container " + this.state.mapOpen}>
          <div className="lockup"><img className="lockup-img top" srcSet={siya} alt="" /><img className="lockup-img bottom" srcSet={logo} alt="" /><img className="lockup-img shadow-lockup" srcSet={shadow} alt="" /></div>
        <TweetContainer assign={this.assignSlide} allTweets={this.state.tweetArr} chosen={this.state.chosenProv} open={this.state.masonOpen} toggleOpen={this.toggleOpenMasonary}/>
        <TweetCarousel close={this.closeCarousel} visible={this.state.carOpen} slide={this.state.currSlide} chosen={this.state.chosenProv} slides={this.state.tweetArr}/>
        <Popup showWhat={this.state.tweetOrTree} pop={this.state.popup} toggleOpen={this.togglePop} locTop={this.state.locTop} locLeft={this.state.locLeft} openMason={this.toggleOpenMasonary} allTweets={this.state.tweetArr} chosen={this.state.chosenProv} treeChose={this.state.treeLoc}/>
        <Map open={this.state.mapOpen} width={50} height={50} triggerPop={this.popUp} provCounts={[this.state.ec, this.state.fs, this.state.gauteng, this.state.kzn, this.state.limpopo, this.state.mpumalanga, this.state.nc, this.state.nw, this.state.wc]}/>
        <InfoTab open={this.state.mapOpen} provCounts={[this.state.ec, this.state.fs, this.state.gauteng, this.state.kzn, this.state.limpopo, this.state.mpumalanga, this.state.nc, this.state.nw, this.state.wc]} />
        <InfoTabMobi open={this.state.mapOpen} provCounts={[this.state.ec, this.state.fs, this.state.gauteng, this.state.kzn, this.state.limpopo, this.state.mpumalanga, this.state.nc, this.state.nw, this.state.wc]} />
        {/* <Footer/> */}
      </div>
    );
  }
}

export default ShadeContainer;
