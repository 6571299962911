import React, { Component } from "react";
import "./infoTabMobi.scss";
import Draggable from "react-draggable";
import treeIcon from "../resources/Shade/tree-icon.png";
import statsIcon from "../resources/Shade/stats-icon.png";
// import treePic from "../resources/Shade/trees/tree1.jpg";
import newtown from "../resources/Shade/trees/newtown.jpeg";
import melrone from "../resources/Shade/trees/melrose.jpg";
import cape from "../resources/Shade/trees/capetown.jpg";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

class InfoTabMobi extends React.Component {
  state = {
    rank: "",
    rankClosed: false,
    treeClosed: false
  };
  constructor(props) {
    super(props);

    this.toggleRankSection= this.toggleRankSection.bind(this);
    this.toggleTreeSection= this.toggleTreeSection.bind(this);
  }

  sortRanks(arr) {
    var tempArr = arr.slice();
    var nums = this.props.provCounts.slice();
    for (var i = 0; i < tempArr.length; i++) {
      // Last i elements are already in place
      for (var j = 0; j < tempArr.length - i - 1; j++) {
        // Checking if the item at present iteration
        // is greater than the next iteration
        if (nums[j] < nums[j + 1]) {
          // If the condition is true then swap them
          var tempNum = nums[j];
          nums[j] = nums[j + 1];
          nums[j + 1] = tempNum;
          var temp = tempArr[j];
          tempArr[j] = tempArr[j + 1];
          tempArr[j + 1] = temp;
        }
      }
    }
    return tempArr;
  }

  fillShade() {
    // console.log(this.props.provCounts)

    var rankings = [];
    var provs = [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ];
    var most = Math.max(
      this.props.provCounts[0],
      this.props.provCounts[1],
      this.props.provCounts[2],
      this.props.provCounts[3],
      this.props.provCounts[4],
      this.props.provCounts[5],
      this.props.provCounts[6],
      this.props.provCounts[7],
      this.props.provCounts[8]
    );
    // console.log(most)
    var count = 0;
    this.props.provCounts.forEach((element) => {
      rankings.push(
        <div data-count={provs[count]} key={count} className="rank-holder">
          <div className="rank-label">{provs[count]}</div>
          <div className="counter">{element}</div>
          <div style={{ width: 100 + "%" }} className="rank-bar-outer">
            <div
              className="rank-bar-inner"
              style={{ width: (element / most) * 100 + "%" }}
            ></div>
          </div>
        </div>
      );
      count++;
    });
    // console.log(rankings);
    rankings = this.sortRanks(rankings);
    // console.log(rankings);
    this.setState({
      rank: rankings,
    });
  }

  componentDidMount() {
    this.fillShade();
  }

  componentDidUpdate(prevProps) {
    if (this.props.provCounts !== prevProps.provCounts) {
      this.fillShade();
    }
  }

  toggleTree(e) {
    e.target.classList.toggle("active");
    var content = document.querySelector(".tree-tab.content");
    if (e.target.classList.contains("active")) {
      content.style.maxHeight = null;
      content.style.padding = null;
    } else {
      content.style.maxHeight = "0px";
      content.style.padding = "0px";
    }
  }

  toggleRank(e) {
    e.target.classList.toggle("active");
    var content = document.querySelector(".rank-tab.content");
    if (e.target.classList.contains("active")) {
      content.style.maxHeight = null;
      content.style.padding = null;
    } else {
      content.style.maxHeight = "0px";
      content.style.padding = "0px";
    }
  }

  toggleTreeSection(){
      if(!this.state.treeClosed){
          if(this.state.rankClosed){
            document.querySelector(".rankCollapse").classList.remove("hide");
            document.querySelector(".rank-tab").classList.remove("hide");
            this.setState({
                rankClosed: false
            })
          }
          else{
            document.querySelector(".rankCollapse").classList.add("hide");
            document.querySelector(".rank-tab").classList.add("hide");
            this.setState({
                rankClosed: true
            })
          }
      }
      else{
          this.setState({
              treeClosed: false,
              rankClosed: true
          })
          document.querySelector(".treeCollapse").classList.remove("hide");
          document.querySelector(".tree-tab").classList.remove("hide");
          document.querySelector(".rankCollapse").classList.add("hide");
        document.querySelector(".rank-tab").classList.add("hide");
      }
  }

  toggleRankSection(){
    if(!this.state.rankClosed){
        if(this.state.treeClosed){
            document.querySelector(".treeCollapse").classList.remove("hide");
        document.querySelector(".tree-tab").classList.remove("hide");
        this.setState({
            treeClosed: false
        })
        }
        else{
            document.querySelector(".treeCollapse").classList.add("hide");
        document.querySelector(".tree-tab").classList.add("hide");
        this.setState({
            treeClosed: true
        })
        }
    }else{
        this.setState({
            rankClosed: false,
            treeClosed: true
        })
        document.querySelector(".rankCollapse").classList.remove("hide");
        document.querySelector(".rank-tab").classList.remove("hide");
        document.querySelector(".treeCollapse").classList.add("hide");
        document.querySelector(".tree-tab").classList.add("hide");
    }
}

  render() {
    return (
      <Draggable handle=".grab" axis="y" bounds={{ top: -450, bottom: 0 }}>
        <div className={"info-tab-container-mobi " + this.props.open}>
          <div className="grab">
            <div className="line"></div>
          </div>
          <div className="map-icons no-cursor">
            <a href="#" onClick={()=>this.toggleTreeSection()}>
              <img className={"iconImage " + this.state.rankClosed} srcSet={treeIcon} alt="" />
            </a>
            {/* <a href="#shaaaade"><img className="iconImage" srcSet={shadeIcon} alt="" /></a> */}
            <a href="#" onClick={()=>this.toggleRankSection()}>
              <img className={"iconImage " + this.state.treeClosed} srcSet={statsIcon} alt="" />
            </a>
          </div>
          <div className="pull-up-content">
            <button
              class="collapsible treeCollapse active"
              onClick={(e) => this.toggleTree(e)}
            >
              Twirra shade
            </button>
            <div className="tree-tab content">
              <SimpleReactLightbox>
                <SRLWrapper>
                  <div className="tree">
                    <img srcSet={newtown} alt="" />
                    <p className="treeLoc">Newtown</p>
                    <a target="_blank" href="https://goo.gl/maps/6pgW4vcyp5JhXJE4A" className="btn-cta view-btn">View location</a>
                  </div>
                  <div className="tree">
                    <img srcSet={melrone} alt="" />
                    <p className="treeLoc">Melrose</p>
                    <a target="_blank" href="https://goo.gl/maps/QWv8ZMwFAMVVyoup6" className="btn-cta view-btn">View location</a>
                  </div>
                  <div className="tree">
                    <img srcSet={cape} alt="" />
                    <p className="treeLoc">Cape Town</p>
                    <a target="_blank" href="https://goo.gl/maps/sbMUVGaFgE9kSZzz9" className="btn-cta view-btn">View location</a>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
            <button
              class="collapsible rankCollapse active"
              onClick={(e) => this.toggleRank(e)}
            >
              Shadiest spots
            </button>
            <div class="rank-tab content">
              {/* <p>Shadiest spots</p> */}
              {this.state.rank}
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

export default InfoTabMobi;
