import React from 'react';
import { XMasonry, XBlock } from "react-xmasonry";
import "./tweet-container.scss"
import Tweet from "./tweet"
import close from "../resources/icons/close.svg"

function TweetContainer(props){
    let tweetList = [];
    var id =0;
    props.allTweets.forEach(element => {
        if(element[1] == props.chosen){
            var rand = Math.random() * (3 - 0) + 1;
            tweetList.push(<XBlock width={1} key={id} onClick={(e)=>{props.assign(parseInt(e.currentTarget.getAttribute("data-key")))}} ><Tweet tweetName={element[0]} tweetContent={element[2]} /></XBlock>)
            id++;
        }
    });
        return (
            <div className={"tweet-container " + props.open} >
                <div className="close-mason"><img onClick={()=>props.toggleOpen("hidden", props.chosen, "hidden","visible")} srcSet={close} alt="" /></div>
                <XMasonry targetBlockWidth={400} maxColumns="3">
                    {tweetList}
                {/* <XBlock>
                    <Tweet tweetName="test name" tweetContent="Indoda must but can he can?" />
                </XBlock>
                <XBlock width={ 2 }>
                    <div className="card">
                        <h1>Wider card</h1>
                        <p>Any text!</p>
                    </div>
                </XBlock> */}
            </XMasonry>
        </div>
        );
}
 
export default TweetContainer;