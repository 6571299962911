/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
// xdfghjkl;
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/compressed_bench.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[0.01, 0.01, 0.01]}>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60.geometry}
            material={materials.Material_20}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_1.geometry}
            material={materials.Material_0}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_2.geometry}
            material={materials.Material_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_3.geometry}
            material={materials.Material_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_4.geometry}
            material={materials.Material_3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_5.geometry}
            material={materials.Material_4}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_6.geometry}
            material={materials['Material_4.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_7.geometry}
            material={materials['Material_4.002']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_8.geometry}
            material={materials['Material_4.003']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_9.geometry}
            material={materials['Material_4.004']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_10.geometry}
            material={materials['Material_4.005']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_11.geometry}
            material={materials['Material_4.006']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_12.geometry}
            material={materials['Material_4.007']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_13.geometry}
            material={materials['Material_4.008']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_14.geometry}
            material={materials['Material_4.009']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_15.geometry}
            material={materials['Material_4.010']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_16.geometry}
            material={materials.Material_5}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_17.geometry}
            material={materials.Material_6}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_18.geometry}
            material={materials['Material_4.011']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_19.geometry}
            material={materials['Material_4.012']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_20.geometry}
            material={materials['Material_4.013']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_21.geometry}
            material={materials['Material_4.014']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_22.geometry}
            material={materials['Material_4.015']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_23.geometry}
            material={materials['Material_4.016']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_24.geometry}
            material={materials.Material_7}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_25.geometry}
            material={materials.Material_8}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_26.geometry}
            material={materials['Material_4.017']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_27.geometry}
            material={materials['Material_4.018']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_28.geometry}
            material={materials['Material_4.019']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_29.geometry}
            material={materials.Material_9}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_30.geometry}
            material={materials.Material_10}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_31.geometry}
            material={materials.Material_11}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_32.geometry}
            material={materials.Material_12}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_33.geometry}
            material={materials.Material_13}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_34.geometry}
            material={materials.Material_14}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_35.geometry}
            material={materials.Material_15}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_36.geometry}
            material={materials['Material_4.020']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_37.geometry}
            material={materials['Material_4.021']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_38.geometry}
            material={materials['Material_4.022']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_39.geometry}
            material={materials['Material_4.023']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_40.geometry}
            material={materials['Material_4.024']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_41.geometry}
            material={materials['Material_4.025']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_42.geometry}
            material={materials['Material_4.026']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_43.geometry}
            material={materials.Material_16}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_44.geometry}
            material={materials.Material_17}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_45.geometry}
            material={materials.Material_18}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_46.geometry}
            material={materials['Material_4.027']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_47.geometry}
            material={materials['Material_4.028']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_48.geometry}
            material={materials['Material_4.029']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_49.geometry}
            material={materials['Material_4.030']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_50.geometry}
            material={materials.Material_19}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_51.geometry}
            material={materials['Material_4.031']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_52.geometry}
            material={materials['Material_4.032']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_53.geometry}
            material={materials['Material_4.033']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_54.geometry}
            material={materials['Material_4.034']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_55.geometry}
            material={materials['Material_4.035']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_56.geometry}
            material={materials['Material_4.036']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_57.geometry}
            material={materials['Material_4.037']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_58.geometry}
            material={materials['Material_4.038']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_59.geometry}
            material={materials['Material_4.039']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_60.geometry}
            material={materials['Material_4.040']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_61.geometry}
            material={materials['Material_4.041']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_62.geometry}
            material={materials['Material_4.042']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_63.geometry}
            material={materials['Material_4.043']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_64.geometry}
            material={materials['Material_4.044']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_65.geometry}
            material={materials.Material_21}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_66.geometry}
            material={materials.Material_22}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_67.geometry}
            material={materials.Material_23}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_68.geometry}
            material={materials['Material_4.045']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_69.geometry}
            material={materials.Material_24}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_70.geometry}
            material={materials.Material_25}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_71.geometry}
            material={materials.Material_26}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_72.geometry}
            material={materials.Material_27}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_73.geometry}
            material={materials.Material_28}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_74.geometry}
            material={materials.Material_29}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_75.geometry}
            material={materials.Material_30}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_76.geometry}
            material={materials['Material_4.046']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_77.geometry}
            material={materials['Material_4.047']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_78.geometry}
            material={materials['Material_4.048']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_79.geometry}
            material={materials['Material_4.049']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_80.geometry}
            material={materials['Material_4.050']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_81.geometry}
            material={materials['Material_4.051']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_82.geometry}
            material={materials['Material_4.052']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_83.geometry}
            material={materials['Material_4.053']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_84.geometry}
            material={materials['Material_4.054']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_85.geometry}
            material={materials['Material_4.055']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_86.geometry}
            material={materials['Material_4.056']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_87.geometry}
            material={materials['Material_4.057']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_88.geometry}
            material={materials.Material_31}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_89.geometry}
            material={materials.Material_32}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_90.geometry}
            material={materials.Material_33}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_91.geometry}
            material={materials.Material_34}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_92.geometry}
            material={materials.Material_35}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_93.geometry}
            material={materials.Material_36}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_94.geometry}
            material={materials['Material_4.058']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_95.geometry}
            material={materials['Material_4.059']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_96.geometry}
            material={materials['Material_4.060']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_97.geometry}
            material={materials['Material_4.061']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh_60_98.geometry}
            material={materials['Material_4.062']}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/compressed_bench.glb')
