import logo from './logo.svg';
import './App.css';
// import Container from "./components/container"
import Nav from "../src/components/navigation"
import Home from "../src/components/home"
// import "./container.scss"
import ShadeContainer from '../src/components/shade-container';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <header className="App-header">
        <div class="container">
            <Nav/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/shade" element={<ShadeContainer/>}/>
            </Routes>
        </div>
        </header>
    </Router>
  );
}

export default App;
