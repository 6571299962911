import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tweet from "./tweet"
import "./tweet-carousel.scss";
import share from "../resources/icons/share-white.png"
import shadow from "../resources/icons/shadow.png"
import close from "../resources/icons/close.svg"

class TweetCarousel extends React.Component {
    state={
        tweets:[]
    }

    constructor(props){
        super(props);

        // this.populate = this.populate.bind(this)

    }

    componentDidMount(){
        this.populate()
        this.setCurrSlide()
    }

    componentDidUpdate(prevProps){
        if(this.props.chosen !== prevProps.chosen){
            this.populate()
        }
        if(this.props.slide !== prevProps.slide){
            this.setCurrSlide()
        }
    }

    populate(){
        var tweetList=[];
        var id =0;
        this.props.slides.forEach(element => {
            if(element[1] == this.props.chosen){
                // console.log("test")
                var rand = Math.random() * (3 - 0) + 1;
                tweetList.push(<div key={id}><Tweet tweetName={element[0]} tweetContent={element[2]} /><a href="http://twitter.com/share?text=I just did something really shady. I threw some good ol’ South African Shade. An abundant natural resource. Throw some too and you could win hot, new TwirrraShadeProject merch 🔥&url=https://twirrashade.savanna.co.za/&hashtags=TwirrraShadeProject" className="btn-cta">SHARE SHADE <img className="tweet-share" srcSet={share} alt="" /></a><div className="shadow"><img className="btn-shadow" srcSet={shadow} alt="" /></div></div>)
                id++;
            }
        });
        // console.log(tweetList)
        this.setState({
            tweets: tweetList
        })
        // this.slider.slickGoTo(this.props)
    }

    setCurrSlide()
    {
        this.slider.slickGoTo(this.props.slide)
    }
    render() { 
        var settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
          
        return (
        <div className={"carousel-lightbox " + this.props.visible}>
            <div className="close-carousel" onClick={()=>this.props.close()}><img srcSet={close} alt="" /></div>
            <Slider ref={slider => (this.slider = slider)} {...settings}>
                {this.state.tweets}
                {/* <div>
                    <Tweet tweetName="random name" tweetContent="random tweet text that will be displayed as a tweet and stuff and things" />
                    <a href="#" className="btn-cta">SHARE SHADE <img className="tweet-share" srcSet={share} alt="" /></a>
                    <div className="shadow"><img className="btn-shadow" srcSet={shadow} alt="" /></div>
                </div>
                <div>
                    <Tweet tweetName="random name" tweetContent="random tweet text that will be displayed as a tweet and stuff and things" />
                    <a href="#" className="btn-cta">SHARE SHADE <img className="tweet-share" srcSet={share} alt="" /></a>
                    <div className="shadow"><img className="btn-shadow" srcSet={shadow} alt="" /></div>
                </div>
                <div>
                    <h3>3</h3>
                </div>
                <div>
                    <h3>4</h3>
                </div>
                <div>
                    <h3>5</h3>
                </div>
                <div>
                    <h3>6</h3>
                </div> */}
            </Slider>
        </div>);
    }
}
 
export default TweetCarousel;