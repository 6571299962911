import React, { Component } from 'react';
import "./infoTab.scss"

class InfoTab extends React.Component {
    state={
        rank: ""
    }
    constructor(props){
        super(props);
    }


    sortRanks(arr){
        var tempArr = arr.slice();
        var nums = this.props.provCounts.slice();
        for(var i = 0; i < tempArr.length; i++){
            // Last i elements are already in place
            for(var j = 0; j < ( tempArr.length - i -1 ); j++){
                // Checking if the item at present iteration
                // is greater than the next iteration
                if(nums[j] < nums[j+1]){
                // If the condition is true then swap them
                var tempNum = nums[j];
                nums[j] = nums[j+1];
                nums[j+1] = tempNum;
                var temp = tempArr[j]
                tempArr[j] = tempArr[j + 1]
                tempArr[j+1] = temp
                }
            }
        }
        return tempArr;
    
    }

    fillShade(){
        // console.log(this.props.provCounts)

        var rankings = []
        var provs = ["Eastern Cape", "Free State", "Gauteng", "KwaZulu-Natal", "Limpopo", "Mpumalanga","Northern Cape","North West", "Western Cape"];
        var most = Math.max(this.props.provCounts[0], this.props.provCounts[1],this.props.provCounts[2],this.props.provCounts[3],this.props.provCounts[4],this.props.provCounts[5],this.props.provCounts[6],this.props.provCounts[7],this.props.provCounts[8]);
        // console.log(most)
        var count =0;
        this.props.provCounts.forEach(element=>{
            rankings.push(<div data-count={provs[count]} key={count} className="rank-holder"><div className="rank-label">{provs[count]}</div><div className="counter">{element}</div><div style={{width: 100+"%"}} className="rank-bar-outer"><div className="rank-bar-inner" style={{width: element/most*100+"%"}}></div></div></div>);
            count++;
        });
        // console.log(rankings);
        rankings = this.sortRanks(rankings)
        // console.log(rankings);
        this.setState({
            rank: rankings
        })

    }

    componentDidMount(){
        this.fillShade()
    }

    componentDidUpdate(prevProps) {
        if (this.props.provCounts !== prevProps.provCounts) {
            this.fillShade();
          }
    }

    toggleShade(e){
        e.target.classList.toggle("active");
        var content = document.querySelector(".content-desktop");
        if (content.style.maxHeight){
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = "500px";
        }
    }


    render() { 
        return (
        <div className={"info-tab-container " + this.props.open}>
            <div className="shade-rank-container">
                <button class="collapsible" onClick={(e)=> this.toggleShade(e)}>Shadiest spots</button>
                <div class="content-desktop">
                {this.state.rank}
                </div>
            </div>
        </div>
        );
    }
}
 
export default InfoTab;