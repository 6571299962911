import React, { Component } from 'react';
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import {Environment, Stage, OrbitControls } from "@react-three/drei";
import logo from "../resources/icons/logo.svg";
import siya from "../resources/icons/siyavanna.svg"
import tagline from "../resources/home/tagline.png";
import info from "../resources/home/benches.png";
import bottles from "../resources/home/bottles.png";
import arrowDown from "../resources/icons/arrow-down.png";
import Model from "./model";
import "./home.scss"
import Footer from "./footer"
import Throw from "./throw"
import {Link} from "react-router-dom";

class Home extends React.Component {
    state = {
        popup: "close"
    }
    constructor(props){
        super(props);

        // this.onChange = this.onChange.bind(this);
        this.toggleClick = this.toggleClick.bind(this);
    }


    // onChange = (update) =>{
    //     this.setState({popup: update});
    // }
    
    toggleClick(){
        // this.setState({popup:"open"})
        if(this.state.popup == "close"){
            this.setState({
                popup: "open"
            })
        }
        else{
            this.setState({
                popup: "close"
            })
        }
    }

    render() {
        return (
        <div className="home-container">
            <Throw popup={this.state.popup} onPopupChange={this.toggleClick}/>
            <div className="scroll-down"><img class="scroll-icon" src={arrowDown} alt=""/></div>
            {/* <div className="no"><img srcSet={no} alt="" /></div> */}
            <div id="landing" className="landing-container">
                <div className="col2 intro">
                    <img className="siya" srcSet={siya} alt="" />
                    <p className="welcome">Welcome to the</p>
                    <img className="logo welcome-logo" src={logo} alt=""  />
                    <br/>
                    <br/>
                    <img className="tag" src={tagline} alt=""  />
                    <br/>
                    <br/>
                    <p>South Ahh, there’s too much shade on Twirra. So, we’re borrowing some of it to plant our exclusive #TwirraShadeProject trees all over the cowntry to keep you cool this summer. Siyavanna?</p>
                    <p>Throw some shade and you could win hot new limited-edition merch!</p>
                    <div className="button-containers"><Link to="/shade"><a className="btn-cta" href="#"><div >Explore Shade</div></a></Link><a className="btn-cta" href="#" onClick={this.toggleClick}><div>Throw Shade</div></a></div>
                </div>
                <div className="col2 tree">
                {/* <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                    <Suspense fallback={null}>
                        <Stage controls={ref} preset="rembrandt" intensity={1}  environment="city">
                        <Model />
                        </Stage>
                    </Suspense>
                    <OrbitControls ref={ref} autoRotate />
                </Canvas> */}
                {/* <ThreeD/> */}
                <Canvas dpr={[1, 2]} shadows camera={{ fov: 45 }}>
                    <Suspense fallback={null}>
                    {/* <Environment preset="sunset" /> */}
                        <Stage ambientIntensity={0} directIntensity={1.4} environment={null} intensity={0.5} contactShadowOpacity={1} shadowBias={-0.0015} >
                            <Model/>
                        </Stage>
                        <OrbitControls autoRotate enableZoom={false} enablePan={false} maxPolarAngle={2} minPolarAngle={Math.PI/2}/>
                    </Suspense>
                </Canvas>
                </div>
            </div>
            <div id="about" className="info-container">
                <div className="col2 info-left">
                    <img className="info-img" src={info} alt="" />
                </div>
                <div className="col2 info-right">
                    <p>Find your nearest <img src={logo} alt="" className="inline-tagline" /> tree on the map for hot clapbacks that can bring some cool relief from the summer sun this Dezemba! Tag yourself there and you can win some hot merch and ice-cold Savannas. </p>
                        <div className="button-containers"><Link to="/shade"><a className="btn-cta" href="#"><div >Explore Shade</div></a></Link><a className="btn-cta" href="#" onClick={this.toggleClick}><div>Throw Shade</div></a></div>
                </div>
            </div>
            <div id="buy" className="buy-container">
                <div className="col2 info-left">
                    <p>Is 100% Mzansi Shade not enough to cool you off? We got you fam with 100% crisp, dry Savannas. Buy some now. 
Nothing shady about it.</p>
                    <div className="button-containers"><a target="_blank" className="btn-cta" href="https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12036613&model=6001108028044"><div >Buy Now</div></a></div>
                </div>
                <div className="col2 info-right">
                    <img className="info-img" src={bottles} alt="" />
                </div>
            </div>
            <Footer/>
        </div>
        );
    }
}
 
export default Home;