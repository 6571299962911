import React, { Component } from 'react';
import "./navigation.scss";
import logo from "../resources/icons/logo.svg";
import Throw from "./throw"
import {Link} from "react-router-dom";
import Terms from "./terms";

class Nav extends React.Component {
    state = {
        popup: "close",
        terms: "close"
    }

    constructor(props){
        super(props);

        this.update = this.update.bind(this);
        this.updateTerms = this.updateTerms.bind(this);
        this.toggleClick = this.toggleClick.bind(this);
        this.toggleTerms = this.toggleTerms.bind(this);

    }

    closeMenu(){
        const checkbox = document.getElementById("menu__toggle");
        checkbox.checked = !checkbox.checked;
    }

    toggleClick(){
        // this.setState({popup:"open"})
        if(this.state.popup == "close"){
            this.setState({
                popup: "open"
            })
        }
        else{
            this.setState({
                popup: "close"
            })
        }
    }

    toggleTerms(){
        if(this.state.terms == "close"){
            this.setState({
                terms: "open"
            })
        }
        else{
            this.setState({
                terms: "close"
            })
        }
    }
    

    update(){
        this.toggleClick();
        this.closeMenu();
    }

    updateTerms(){
        this.toggleTerms();
        this.closeMenu();
    }

    render() { 
        return (
        <div className="nav-container">
            <Throw popup={this.state.popup} onPopupChange={this.toggleClick}/>
            <Terms popup={this.state.terms} onPopupChange={this.toggleTerms}/>
            <div className="logo-container"><Link to="/"><img className="logo" src={logo} alt="" srcset="" /></Link></div>
            <div className="hamburger-menu">
                <input id="menu__toggle" type="checkbox" />
                <label className="menu__btn" htmlFor="menu__toggle">
                <span></span>
                </label>

                <ul className="menu__box">
                    <li><Link className="menu__item" onClick={this.closeMenu} to="/">Home</Link></li>
                    <li><a className="menu__item" onClick={this.update} href="#">Throw some shade</a></li>
                    <li><Link to="/shade"><a onClick={this.closeMenu} className="menu__item" href="#">Explore shade</a></Link></li>
                    <li><a className="menu__item" onClick={this.closeMenu} href="#buy">Buy now</a></li>
                    <li><a className="menu__item"onClick={this.updateTerms} href="#">Terms & conditions</a></li>
                </ul>
            </div>
        </div>
        )
    }
}
 
export default Nav;