import React, { Component } from "react";
import "./footer.scss";
import footerLogo from "../resources/icons/footer-logo.png";
import no from "../resources/icons/no.png"
import covid from "../resources/icons/Covid-19-Button.png"
import insta from "../resources/icons/insta.svg";
import fb from "../resources/icons/fb.svg";
import twit from "../resources/icons/twit.svg";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <div className="upper">
          <div className="logo-container">
            <img src={footerLogo} alt="" srcset="" />
          </div>
          <div className="social-container">
            <div className="hash">#TwirraShadeProject</div>
            <div className="icons">
              <a className="icon" href="#">
                <img src={fb} alt="" srcset="" />
              </a>
              <a className="icon" href="#">
                <img src={insta} alt="" srcset="" />
              </a>
              <a className="icon" href="#">
                <img src={twit} alt="" srcset="" />
              </a>
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="no-footer">
            <img srcSet={no} alt="" />
          </div>
          <div className="covid-footer">
            <img srcSet={covid} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
