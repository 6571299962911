import React from "react";
import "./popup.scss";
import Tweet from "./tweet";
import close from "../resources/icons/close.svg";
import newtown from "../resources/Shade/trees/newtown.jpg";
import melrone from "../resources/Shade/trees/melrose.jpg";
import cape from "../resources/Shade/trees/capetown.jpg";

function Popup(props) {
  let content;
  if (props.showWhat == "tweet") {
    for (var x in props.allTweets) {
      if (props.allTweets[x][1] == props.chosen) {
        content = (
          <Tweet
            tweetName={props.allTweets[x][0]}
            tweetContent={props.allTweets[x][2]}
          />
        );
      }
    }
  } else {
    if (props.treeChose == "Newtown") {
      content = (
        <div>
          <p className="popup-tree-title">Newtown</p>
          <img className="popup-tree" srcset={newtown} alt="" />
        </div>
      );
    } else if (props.treeChose == "Melrose") {
      content = (
        <div>
          <p className="popup-tree-title">Melrose</p>
          <img className="popup-tree" srcset={melrone} alt="" />
        </div>
      );
    } else {
      content = (
        <div>
          <p className="popup-tree-title">Cape Town</p>
          <img className="popup-tree" srcset={cape} alt="" />
        </div>
      );
    }
  }

  const divStyle = {
    left: props.locLeft,
    top: props.locTop,
  };
  return (
    <div className={"popup-container " + props.pop} style={divStyle}>
      <div className="popup-tweet">{content}</div>
      <div className="close-popup" onClick={() => props.toggleOpen("hidden")}>
        <img srcSet={close} alt="" />
      </div>
      {props.showWhat == "tweet" ? (
        <a
          href="#"
          className="btn-cta"
          onClick={(e) => {
            props.openMason("visible", props.chosen, "hidden", "hidden");
          }}
        >
          VIEW MORE
        </a>
      ) : (
        <a
        target="_blank"
          href="#"
          className="btn-cta"
          onClick={(e) => {
            if (props.treeChose == "Melrose") {
              window.open("https://goo.gl/maps/QWv8ZMwFAMVVyoup6", "_blank");
            } else if (props.treeChose == "Newtown") {
              window.open("https://goo.gl/maps/6pgW4vcyp5JhXJE4A", "_blank");
            } else if (props.treeChose == "Cape town") {
              window.open("https://goo.gl/maps/sbMUVGaFgE9kSZzz9", "_blank");
            }
          }}
        >
          VISIT SITE
        </a>
      )}
    </div>
  );
}

export default Popup;
