import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import { useCookies, CookiesProvider } from "react-cookie";
import Logo from "./resources/age-gate-lock-up.svg"
import footerLogo from "./resources/tagline-age-gate.svg"

function AgeGate() {
  var one = 0;
  var two = 0;
  var three = 0;
  var four = 0;
  const [verified, setVerified] = useState(0);

  const [cookies, setCookie] = useCookies(["age"]);

  useEffect(() => {
    if(cookies){
      if(cookies.age > 18){
        setVerified(true);
      }
    }
  });

  // constructor(props){
  //   super(props);

  //   this.dateOne = this.dateOne.bind(this);
  //   this.dateTwo = this.dateTwo.bind(this);
  //   this.dateThree = this.dateThree.bind(this);
  //   this.dateFour = this.dateFour.bind(this);
  //   this.checkAge = this.checkAge.bind(this);
  // }
  function dateOne(_one) {
    one = _one;
  }

  function dateTwo(_two) {
    two = _two;
  }
  function dateThree(_three) {
    three = _three;
  }
  function dateFour(_four) {
    four = _four;
  }

  function checkAge() {
    var year = one + two + three + four;
    // console.log(age)
    var currYear = new Date().getFullYear();
    var age = currYear - parseInt(year);
    if (age >= 18) {
      setCookie("age", age, {
        maxAge: 604800, // Will expire after 1week (value is in number of sec.)
      });
      setVerified(true);
    }
  }

  function handleChange(_e) {
    var target = _e.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    console.log(myLength, maxLength);
    if (myLength >= maxLength) {
      var next = target;
      while ((next = next.nextElementSibling)) {
        if (next == null) break;
        if (next.tagName.toLowerCase() === "input") {
          next.focus();
          break;
        }
      }
    }
    // Move to previous field if empty (user pressed backspace)
    else if (myLength === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  }

  return (
    <div>
      {!verified ? (
        <div className="age-gate">
          <div className="age-gate-form">
            <div className="age-gate-logo"><img srcSet={Logo}/></div>
            <div className="age-gate-subheading">Are you older than 18?</div>
            <div className="age-gate-message">
              Please confirm that you’re older than 18 to access this content.
            </div>
            <div className="age-back">
              <input
                className="ageYear"
                type="tel"
                onInput={(e) => dateOne(e.target.value)}
                data-type="input-year"
                name="age1"
                id="age1"
                placeholder="Y"
                maxLength="1"
                onChange={(e) => handleChange(e)}
              />
              <input
                className="ageYear"
                type="tel"
                onInput={(e) => dateTwo(e.target.value)}
                data-type="input-year"
                name="age1"
                id="age1"
                placeholder="Y"
                maxLength="1"
                onChange={(e) => handleChange(e)}
              />
              <input
                className="ageYear"
                type="tel"
                onInput={(e) => dateThree(e.target.value)}
                data-type="input-year"
                name="age1"
                id="age1"
                placeholder="Y"
                maxLength="1"
                onChange={(e) => handleChange(e)}
              />
              <input
                className="ageYear"
                type="tel"
                onInput={(e) => dateFour(e.target.value)}
                data-type="input-year"
                name="age1"
                id="age1"
                placeholder="Y"
                maxLength="1"
                onChange={(e) => handleChange(e)}
              />
              <br/>
              <input className="age-gate-submit" value="SUBMIT" onClick={() => checkAge()} type="submit" />
            </div>
            <div className="age-gate-additional">
              This site uses cookies to provide you with a better experience. By
              logging into this site, you are agreeing to the use of cookies.
            </div>
          </div>
          <div className="age-gate-footer"><img srcSet={footerLogo}/></div>
        </div>
      ) : (
        <App />
      )}
    </div>
  );
}

export default AgeGate;

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <SimpleReactLightbox>
        <AgeGate />
        {/* <App/> */}
      </SimpleReactLightbox>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
